export function debounceEvent(htmlElement: HTMLElement, eventName: string, delay: number) {
	registerEvent(htmlElement, eventName, delay, debounce);
}

export function throttleEvent(htmlElement: HTMLElement, eventName: string, delay: number) {
	registerEvent(htmlElement, eventName, delay, throttle);
}

function registerEvent(htmlElement: HTMLElement, eventName: string, delay: number, filterFunction: Function) {
	let raisingEvent = false;
	let eventHandler = filterFunction(function (e:any) {
		raisingEvent = true;
		try {
			htmlElement.dispatchEvent(e);
		} finally {
			raisingEvent = false;
		}
	}, delay);

	htmlElement.addEventListener(eventName, e => {
		if (!raisingEvent) {
			e.stopImmediatePropagation();
			eventHandler(e);
		}
	});
}

function debounce(this:any, func:Function, wait:number) {
	let timer: any;
	return (...args: any) => {
		clearTimeout(timer);
		timer = setTimeout(() => { func.apply(this, args); }, wait);
	};
}

function throttle(func:Function, wait:number) {
	var context:any, args:any, result:any;
	var timeout:any = null;
	var previous = 0;
	var later = function () {
		previous = Date.now();
		timeout = null;
		result = func.apply(context, args);
		if (!timeout) context = args = null;
	};
	return function (this: any, ) {
		var now = Date.now();
		if (!previous) previous = now;
		var remaining = wait - (now - previous);
		context = this;
		args = arguments;

		if (!timeout) {
			timeout = setTimeout(later, remaining);
		}
		return result;
	};
};