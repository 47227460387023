export function BlazorReconnectObserver() {	
	async function attemptReload() {
		var response = await fetch(''); // Check if the server really is back and is returning 200 HOKAY!
		if (response.ok) {
			location.reload();
		}
	}

	// Wait until the Blazor 'reload' button appears
	const observer = new MutationObserver((mutations, observer) => {
		if (document.querySelector('#components-reconnect-modal h5 a')) {
			// Now every 10 seconds, see if the server appears to be back, and if so, reload
			observer.disconnect();
			attemptReload();
			setInterval(attemptReload, 10000);
		}
	});
	
	observer.observe(document.body, { childList: true, subtree: true });
}


