export function inViewPort(element: HTMLElement, blazorComponent: any, callbackMethodName: String) {

	const observer = new IntersectionObserver((entries) => {
		if (entries[0].intersectionRatio <= 0) { // If not in view
			blazorComponent.invokeMethodAsync(callbackMethodName, false);
		} else {
			blazorComponent.invokeMethodAsync(callbackMethodName, true);
		}
	});

	observer.observe(element);
}

export function ViewportObserver() {
	var elements = document.querySelectorAll('[data-viewport-class]');

	for (var i = 0; i < elements.length; i++) {
		const element = elements[i];

		if (element.getAttribute('data-viewport-observer') != 'true') {

			element.setAttribute('data-viewport-observer', 'true');

			const observer = new IntersectionObserver((entries) => {

				var className = element.getAttribute("data-viewport-class") as string;
				var removeClassName = element.getAttribute("data-viewport-class-remove") as string;

				if (!element.hasAttribute('data-viewport-observer-recent')) {
					//* prevent classes from changin to quicky
					element.setAttribute('data-viewport-observer-recent', 'true');
					window.setTimeout(() => { element.removeAttribute('data-viewport-observer-recent') }, 100);

					if (entries[0].intersectionRatio <= 0) { // If not in view
						element.classList.remove(removeClassName);
					} else {
						element.classList.add(className);
					}
				}
			});

			observer.observe(element);
		}
	}
}