export function observeElementSize(className = 'observe-size') {

	var elementChanged = (entries: ResizeObserverEntry[], observer: ResizeObserver) => {
		for (let entry of entries) {
			var htmlElement = entry.target as HTMLElement;

			var prefix = 'element';
			var customPrefix = htmlElement.attributes.getNamedItem('data-size-prefix');
			if (customPrefix != null) prefix = customPrefix.value;

			var target = entry.target;
			var targetClass = htmlElement.attributes.getNamedItem('data-target-element');
			if (targetClass != null) target = target.getElementsByClassName(targetClass.value)[0];

			htmlElement.style.setProperty('--' + prefix + '-width', String(target.clientWidth) + 'px');
			htmlElement.style.setProperty('--' + prefix + '-height', String(target.clientHeight) + 'px');
		}
	}

	var resizeObserver = new ResizeObserver(elementChanged);

	var updateSizeVariables = () => {
		var elements = document.getElementsByClassName(className);

		for (var i = 0; i < elements.length; i++) {
			//* Lets assume the resize observer is intelligent and only lists each element once
			resizeObserver.observe(elements[i]);
		}
	}

	updateSizeVariables();

	const config = { attributes: false, childList: true, subtree: true };
	const observer = new MutationObserver((mutations, observer) => {
		updateSizeVariables();
	});

	observer.observe(document.getElementsByTagName("body")[0], config);
}